import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import Container from "@Components/views/Container";

import styles from "./Breadcrumb.styles";

const ReactBreadcrumb = loadable(() =>
	import("@micado-digital/react-breadcrumb/ReactBreadcrumb")
);

const Breadcrumb = () => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const { breadcrumbs, layoutVariant } = pageData;

	useEffect(() => {
		breadcrumbs?.pop();
	}, [breadcrumbs]);

	// if (
	// 	breadcrumbs?.length > 1 &&
	// 	breadcrumbs[breadcrumbs?.length - 1].title === breadcrumbs[breadcrumbs?.length - 2].title
	// ) {
	// 	breadcrumbs?.pop(); // Entfernt das letzte Element aus dem Array
	// }

	return (
		<div
			className={clsx(css.breadcrumb, "mco-view-component-breadcrumb", {
				[css.breadcrumbNoAnimation]: layoutVariant === "no-animation"
			})}
		>
			<Container className="mco-view-component-breadcrumb__container" maxWidth="lg">
				<ReactBreadcrumb
					data={breadcrumbs}
					homeIcon={<ReactSVGIcon size={24} src="/img/icons/home.svg" />}
					homeLabel="Home"
					homeLink="/"
					separator=""
				/>
			</Container>
		</div>
	);
};

export default Breadcrumb;
